import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import './App.css';
import { EffectCards } from 'swiper/modules';

// Import images
import image1 from './images/1.jpeg';
import image2 from './images/2.jpeg';
import image3 from './images/3.jpeg';
import image4 from './images/4.jpeg';
import image5 from './images/5.jpeg';
import image6 from './images/6.jpeg';
import image7 from './images/7.jpeg';
import image8 from './images/8.jpeg';
import image9 from './images/9.jpeg';
import image10 from './images/10.jpeg';
import logo from './images/logo.png';

import instagram from './icons/instagram.webp';
import facebook from './icons/facebook.png';
import tiktok from './icons/tiktok.png';
import whatsapp from './icons/whatsapp.png';

const images = [
	image1, image2, image3, image4, image5, image6, image7, image8, image9, image10
];

function App() {
	return (
		<div className="app">
			<div className='logos'>
				<a href="https://instagram.com/geostory.tours" target="_blank" rel="noreferrer">
					<img src={instagram} alt="Instagram" />
				</a>
				<a href="https://facebook.com/geostory.tours" target="_blank" rel="noreferrer">
					<img src={facebook} alt="Facebook" />
				</a>
				<a href="https://tiktok.com/@geostorytours" target="_blank" rel="noreferrer">
					<img src={tiktok} alt="TikTok" />
				</a>
			</div>
			<Swiper
				effect={'cards'}
				grabCursor={true}
				modules={[EffectCards]}
				className="mySwiper"
			>
				{images.map((image, index) => (
					<SwiperSlide>
						<img src={image} alt={`Slide ${index + 1}`} />
					</SwiperSlide>
				))}
				<SwiperSlide>
					<div className='last-slide'>
						<img src={logo} alt="Geostory logo" />
						<a href="https://instagram.com/geostory.tours" target="_blank" rel="noreferrer">
							See more
						</a>
					</div>
				</SwiperSlide>
			</Swiper>
			<div className="mobile">
				<a href="https://wa.me/995550507716">
					<img src={whatsapp} alt="WhatsApp" />
					<span>+995 550 50 77 16</span>
				</a>
				<span>geostory.tours@gmail.com</span>
			</div>
		</div>
	);
}

export default App;
